import React from 'react';
import { SocialLinkContainer } from '../components/SocialLinkContainer';
import { RedCircleBorder } from '../components/RedCircleBorder.jsx';
import { OuterContainer } from '../components/OuterContainer.jsx';
import { ImageContainer } from '../components/ImageContainer.jsx';
import { TextBox } from '../components/TextBox.jsx';
import { Text } from '../components';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import theme from '../../theme/theme';
import { ThemeProvider } from 'styled-components';

import { FaInstagram, FaTwitter, FaFacebookF } from 'react-icons/fa';

const StayInTouchPage = () => {
    const data = useStaticQuery(graphql`
        query {
            file(relativePath: { eq: "stayintouch.png" }) {
                childImageSharp {
                    fluid(maxHeight: 800, quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);
    return (
        <ThemeProvider theme={theme}>
            <OuterContainer flexDirection="row-reverse">
                <TextBox textAlign="right">
                    <Text as="h1" color={theme.colors.primary}>
                        Stay in Touch
                    </Text>
                    <Text as="p">
                        Follow us to stay up to date and not miss out on any
                        CheaprEats news!
                    </Text>
                    <SocialLinkContainer>
                        {icons.map((icon) => (
                            <RedCircleBorder>{icon}</RedCircleBorder>
                        ))}
                    </SocialLinkContainer>
                    <Text as="p">
                        We’re listening! If you are a vendor or know of one that
                        would be interested in partnering with us, contact us
                        today! We’d love to hear from you, so don’t hesitate to
                        send us an email.
                    </Text>
                    <Text as="h1" color={theme.colors.primary}>
                        hello@cheapreats.com
                    </Text>
                </TextBox>
                <ImageContainer>
                    <Img fluid={data.file.childImageSharp.fluid} />
                </ImageContainer>
            </OuterContainer>
        </ThemeProvider>
    );
};

const icons = [<FaTwitter />, <FaFacebookF />, <FaInstagram />];

export default StayInTouchPage;
